import React from 'react';

import '../CRM/containers/CRMDashboard/components/AnalyticsContainer/AnalyticsContainer.scss';
import Analytics from './Analytics';
import { useParams } from 'react-router-dom';

export const AnalyticsProvider = () => {
  const { id: projectId, tab: reportsTab, selectedRange, selectedTimeInterval } = useParams();
  const split = selectedRange.split('-') || [];
  return (
    <Analytics
      projectId={projectId}
      reportsTab={reportsTab}
      start={Number(split[0]) || Date.now()}
      end={Number(split[1]) || Date.now()}
      selectedTimeInterval={selectedTimeInterval}
    />
  );
};
