import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import '../CRM/containers/CRMDashboard/components/AnalyticsContainer/AnalyticsContainer.scss';
import Overview from '../CRM/containers/CRMDashboard/components/AnalyticsContainer/tabs/Overview';
import WiseAgent from '../CRM/containers/CRMDashboard/components/AnalyticsContainer/tabs/WiseAgent';
import TeamPerformance from '../CRM/containers/CRMDashboard/components/AnalyticsContainer/tabs/TeamPerformance';
import Feedback from '../CRM/containers/CRMDashboard/components/AnalyticsContainer/tabs/Feedback';

import {
  containedConversationsData,
  containedConversationsLabels,
  containedConversationsTitle,
} from '../../store/analytics/selectors';
import {
  setContainedConversationsData,
  setContainedConversationsLabels,
  setContainedConversationsTitle,
} from '../../store/analytics/actions';
import { twin } from '../../utils/twin';

let currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const Analytics = (props) => {
  const {
    reportsTab,
    start,
    end,
    selectedTimeInterval,
    projectId,
    containedConversationsData,
    containedConversationsLabels,
    containedConversationsTitle,
    setContainedConversationsData,
    setContainedConversationsLabels,
    setContainedConversationsTitle,
  } = props;
  const convData = twin(containedConversationsData);
  const [withoutTimeStart] = new Date(start).toISOString().split('T');
  const [withoutTimeEnd] = new Date(end).toISOString().split('T');
  const dateName = withoutTimeStart + ' to ' + withoutTimeEnd;
  const title =
    reportsTab === 'All' ? `Wisevoice Report ${dateName}` : `${reportsTab} report ${dateName}`;

  useEffect(() => {}, [
    projectId,
    selectedTimeInterval,
    start,
    end,
    setContainedConversationsData,
    setContainedConversationsLabels,
    setContainedConversationsTitle,
  ]);

  return (
    <div className="AnalyticsContainer" style={{ flexDirection: 'column' }}>
      <div className="reports-title" style={{ marginBottom: '25px' }}>
        <h3>{title}</h3>
      </div>
      <div className="timezone-text regular-text"> generated in {currentTimezone} timezone</div>
      <div className="body-style-primary" style={{ padding: 10 }}>
        <div className="body-content" style={{ padding: '0 10px' }}>
          {(reportsTab === 'Overview' || reportsTab === 'All') && (
            <Overview
              forPrint
              projectId={projectId}
              startTimestamp={start}
              endTimestamp={end}
              selectedTimeInterval={selectedTimeInterval}
              containedConversationsData={convData}
              containedConversationsLabels={containedConversationsLabels}
              containedConversationsTitle={containedConversationsTitle}
            />
          )}
          {(reportsTab === 'Wise Agent' || reportsTab === 'All') && (
            <WiseAgent
              forPrint
              projectId={projectId}
              startTimestamp={start}
              endTimestamp={end}
              selectedTimeInterval={selectedTimeInterval}
              containedConversationsData={convData}
              containedConversationsLabels={containedConversationsLabels}
              containedConversationsTitle={containedConversationsTitle}
              all={reportsTab === 'All'}
            />
          )}
          {(reportsTab === 'Team Performance' || reportsTab === 'All') && (
            <TeamPerformance
              forPrint
              all={reportsTab === 'All'}
              projectId={projectId}
              startTimestamp={start}
              endTimestamp={end}
              selectedTimeInterval={selectedTimeInterval}
            />
          )}
          {(reportsTab === 'Feedback' || reportsTab === 'All') && (
            <Feedback
              forPrint
              projectId={projectId}
              startTimestamp={start}
              endTimestamp={end}
              selectedTimeInterval={selectedTimeInterval}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  containedConversationsData: containedConversationsData(state),
  containedConversationsLabels: containedConversationsLabels(state),
  containedConversationsTitle: containedConversationsTitle(state),
});

const mapDispatchToProps = {
  setContainedConversationsData,
  setContainedConversationsLabels,
  setContainedConversationsTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
