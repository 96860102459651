import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { selectReportsTab } from 'store/crm/selectors';

import './AnalyticsContainer.scss';
import WiseAgent from './tabs/WiseAgent';
import Overview from './tabs/Overview';
import TeamPerformance from './tabs/TeamPerformance';
import Feedback from './tabs/Feedback';
import ExportOptions from './export/ExportOptions';
import {
  containedConversationsData,
  containedConversationsLabels,
  containedConversationsTitle,
} from '../../../../../../store/analytics/selectors';

import {
  setContainedConversationsData,
  setContainedConversationsLabels,
  setContainedConversationsTitle,
} from '../../../../../../store/analytics/actions';
import { twin } from '../../../../../../utils/twin';
import BasicDateRangePicker from '../../../../../../components/common/DateRangePicker/DateRangePicker';
import languageMap from 'Language/Language';
const moment = window.moment;

let currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const AnalyticsContainer = (props) => {
  const [start, setStart] = useState(moment().subtract(6, 'days').startOf('day'));
  const [end, setEnd] = useState(moment());
  const [pageTitle, setPageTitle] = useState('reports');
  const selectedTimeInterval = '1d';
  const { reportsTab, projectId, setConvData, setConvLabels, setConvTitle } = props;

  const convData = twin(props.containedConversationsData);

  const camelize = (str) => {
    setPageTitle(str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()));
  };

  const handleIntervalChange = (start, end) => {
    setStart(start);
    setEnd(end);
  };

  let startTimestamp = start.valueOf();
  let endTimestamp = end.valueOf();
  useEffect(() => {
    camelize(reportsTab);
  }, [
    selectedTimeInterval,
    startTimestamp,
    endTimestamp,
    projectId,
    setConvData,
    setConvLabels,
    setConvTitle,
    reportsTab,
  ]);

  return (
    <div className="AnalyticsContainer">
      <div className="body-style-primary">
        <div className="body-style-container">
          <div className="body-inner-container">
            <div className="filters-container">
              <label className="filters-label">{languageMap[pageTitle]}</label>
              <div className="time-zone-text small-text">{`Reports are in ${currentTimezone} timezone`}</div>
              <div className="dropdown-container">
                <BasicDateRangePicker start={start} end={end} onChange={handleIntervalChange} />
                <ExportOptions
                  tab={reportsTab}
                  projectId={projectId}
                  startTimestamp={startTimestamp}
                  endTimestamp={endTimestamp}
                  selectedTimeInterval={selectedTimeInterval}
                />
              </div>
            </div>
            <div className="body-content">
              {reportsTab === 'Overview' && (
                <Overview
                  projectId={projectId}
                  startTimestamp={startTimestamp}
                  endTimestamp={endTimestamp}
                  selectedTimeInterval={selectedTimeInterval}
                  containedConversationsData={convData}
                  containedConversationsLabels={props.containedConversationsLabels}
                  containedConversationsTitle={props.containedConversationsTitle}
                  currentTimezone={currentTimezone}
                />
              )}
              {reportsTab === 'Wise Agent' && (
                <WiseAgent
                  projectId={projectId}
                  startTimestamp={startTimestamp}
                  endTimestamp={endTimestamp}
                  selectedTimeInterval={selectedTimeInterval}
                  containedConversationsData={convData}
                  containedConversationsLabels={props.containedConversationsLabels}
                  containedConversationsTitle={props.containedConversationsTitle}
                  currentTimezone={currentTimezone}
                />
              )}
              {reportsTab === 'Team Performance' && (
                <TeamPerformance
                  projectId={projectId}
                  startTimestamp={startTimestamp}
                  endTimestamp={endTimestamp}
                  selectedTimeInterval={selectedTimeInterval}
                  currentTimezone={currentTimezone}
                />
              )}
              {reportsTab === 'Feedback' && (
                <Feedback
                  projectId={projectId}
                  startTimestamp={startTimestamp}
                  endTimestamp={endTimestamp}
                  selectedTimeInterval={selectedTimeInterval}
                  currentTimezone={currentTimezone}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  reportsTab: selectReportsTab(state),

  containedConversationsData: containedConversationsData(state),
  containedConversationsLabels: containedConversationsLabels(state),
  containedConversationsTitle: containedConversationsTitle(state),
});

const mapDispatchToProps = {
  setConvData: setContainedConversationsData,
  setConvLabels: setContainedConversationsLabels,
  setConvTitle: setContainedConversationsTitle,
};
export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsContainer);
